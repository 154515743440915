import React, { useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import './index.scss';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import { Checkbox } from '../../components/Argo-ui';
import { DataContext } from '../../data/DataProvider';
import {
  cancelEdit,
  changeValorParcela,
  isEditValid,
} from '../../helpers/editQuotation';
import { submitProposalDigital } from '../../services/base-service';
import parse from 'html-react-parser';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import MainFooter from '../../components/main-footer/main-footer';
import DefaultLayout from '../../components/layouts/default-layout';
import { useMemo } from 'react';
import { parcelaFormatter } from '../../helpers/parcela';

const block = 'page-finalizar-proposta';
const Page = ({ location }) => {
  const {
    quotation,
    setQuotation,
    setModal,
    originalQuotation,
    setOriginalQuotation,
    responseMotor,
  } = useContext(DataContext);
  const [termsAndConditionsAcceptance, setTermsAndConditionsAcceptance] =
    useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const { handleSubmit } = useForm();

  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) navigate('/');
    if (quotation && !quotation?.coberturas && !quotation?.protocolo)
      return navigate('/coberturas');
  }, [quotation, quotation?.coberturas]);

  const cancel = () => {
    cancelEdit(setQuotation, originalQuotation);
  };

  const parcelaItems = useMemo(() => {
    return parcelaFormatter(responseMotor?.response);
  }, [responseMotor?.response]);

  useEffect(() => {
    setQuotation({
      ...quotation,
      finalizacao: {
        ...quotation?.finalizacao,
        parcela: parcelaItems[0].value,
        valorParcela: parcelaItems[0]?.parcela,
      },
    });
  }, []);

  async function onSubmit() {
    if (quotation?.propostaId) {
      return changeValorParcela(
        quotation,
        responseMotor.response,
        setModal,
        setDisableSave,
        setOriginalQuotation
      );
    }
    setDisableButton(true);
    let response = await submitProposalDigital(quotation);
    if (!response.error) {
      window.location.href = `${process.env.GATSBY_URL_DIGITAL}/protector/ferramentas/cotacoes`;
    } else {
      const error = response.error.replace(/\n/g, '<br />');
      setModal({
        values: {
          headline: 'Ops! ',
          message: parse(error),
          primaryButton: {
            label: 'Ok',
            handler: () => {},
          },
        },
      });
    }
  }

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={block}>
        <MainHeader />
        <PageHeader
          backButtonHandler={() => navigate('/coberturas')}
          editMode={!!quotation?.propostaId}
          title="Finalizar cotação"
          description={
            <span>Confira as informações abaixo para completar a cotação</span>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
            {
              label: 'Dados da empresa',
              path: '/dados-da-empresa',
            },
            {
              label: 'Informações do seguro',
              path: '/informacoes-do-seguro',
            },
            {
              label: 'Avaliação de risco',
              path: '/avaliacao-de-risco',
            },
            {
              label: 'Sistemas protecionais',
              path: '/sistemas-protecionais',
            },
            {
              label: 'Sinistralidade',
              path: '/sinistralidade',
            },
            {
              label: 'Exposição política',
              path: '/exposicao-politica',
            },
            {
              label: 'Coberturas',
              path: '/coberturas',
            },
            {
              label: 'Finalizar cotação',
              path: '/finalizar-proposta',
            },
          ]}
          fixed
          hide={false}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`container--large ${block}--fixed`}>
            <div className={`${block}--row`}>
              <div className={`${block}--item`}>
                <span className={`${block}__label`}>
                  Opções de parcelamento:
                </span>
                <div className={`${block}__parcela-list`}>
                  <ul>
                    {parcelaItems &&
                      parcelaItems.map((parcelaItem, index) => (
                        <li key={index}>{parcelaItem.label}</li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className={`${block}--row-legal`}>
              <div className={`${block}--item`}>
                <p className={`${block}__p`}>
                  a) As informações foram prestadas com exatidão, boa fé e
                  veracidade e assume integral responsabilidade, inclusive pelas
                  não escritas de próprio punho e que tomou conhecimento prévio
                  das Condições Gerais da Apólice;
                </p>
                <p className={`${block}__p`}>
                  b) Autoriza expressamente a inclusão de todos os dados e
                  informações relacionadas ao presente seguro, assim como de
                  todos os eventuais sinistros e ocorrências referentes ao
                  mesmo, em banco de dados, aos quais a seguradora poderá
                  recorrer para análise de riscos e na liquidação de processos
                  de sinistros. Autoriza também a consulta junto à banco de
                  dados de instituições de perfil de crédito, bem como o
                  registro da consulta em tais instituições;
                </p>
                <p className={`${block}__p`}>
                  c) A AKAD Seguros se reserva ao direito de recusar esta
                  proposta até 15 dias após o recebimento da proposta, da
                  documentação solicitada;
                </p>
                <p className={`${block}__p`}>
                  d) A cobertura de seguro só se inicia no momento da entrega
                  protocolada da proposta da AKAD Seguros.
                </p>
              </div>
            </div>
            <div className={`${block}--row-legal`}>
              <div className={`${block}--item`}>
                <Checkbox
                  id="texto-legal"
                  label="Declaro que o segurado está ciente das informações acima"
                  labelSize={`${11 / 13}rem`}
                  onChange={(value) => {
                    trackCustomEvent({
                      category: 'Texto legal',
                      action: 'Click',
                      label: value,
                    });
                    setTermsAndConditionsAcceptance(value);
                  }}
                  fontWeight="bold"
                  large
                />
              </div>
            </div>
          </div>

          <div className={`container--large`}>
            {isEditValid(quotation) && (
              <div className="action_buttons">
                <Button
                  className="button_cta"
                  type="button"
                  theme={'secondary'}
                  onClick={cancel}
                  disabled={disableSave}
                >
                  Cancelar edição
                </Button>
                <Button
                  className={`button_cta button--margin-left`}
                  theme={'tertiary'}
                  disabled={
                    !quotation?.finalizacao?.parcela ||
                    !termsAndConditionsAcceptance ||
                    disableSave
                  }
                >
                  Salvar edição
                </Button>
              </div>
            )}
            {!quotation?.propostaId && (
              <div className="action_buttons">
                <Button
                  className={`button_cta`}
                  type="button"
                  theme={'secondary'}
                  onClick={() => navigate('/resumo')}
                >
                  Carta de cotação
                </Button>
                <Button
                  className={`button_cta button--margin-left`}
                  type="submit"
                  disabled={
                    !quotation?.finalizacao?.parcela ||
                    !termsAndConditionsAcceptance ||
                    disableButton ||
                    disableSave
                  }
                >
                  Salvar cotação
                </Button>
              </div>
            )}
          </div>
        </form>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
