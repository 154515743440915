const parcelaFormatter = (responseMotor) => {
  if (responseMotor) {
    return responseMotor?.map((item, key) => ({
      label: `${key + 1} x sem juros de R$ ${item.parcela.toFixed(2).toString().replace('.', ',')}`,
      value: key + 1,
      parcela: item.parcela,
    }));
  } else {
    return [{ label: 'Parcelas não encontradas', value: 0 }];
  }
};

const setParcelaChange = (quotation, setQuotation, getMotor, selectedItem) => {
  setQuotation({
    ...quotation,
    finalizacao: {
      ...quotation?.finalizacao,
      parcela: selectedItem.value,
      valorParcela: selectedItem?.parcela,
    },
  });
  if (getMotor) {
    getMotor({ ...quotation });
  }
};

export { parcelaFormatter, setParcelaChange };
